.Header {
    background-color: #1f1f1f;
    height: 40px;
    padding: 10px 20px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Logo {
    margin-right: 30px;
    
    & img {
        height: 33px;
        image-rendering: pixelated;
    }
}

.hamburger-button {
    margin-left: auto;
    height: 100%;
    background: none;
    border: none;

    & img {
        height: calc(100% - 10px);
        padding: 5px 5px 5px 5px;
        margin-right: -10px;
        cursor: pointer;
    }
}

.links-vertical {
    right: 0;
    align-items: flex-end;
    display: flex;
    font-size: larger;
    flex-direction: column;
    justify-content: start;
    position: absolute;
    z-index: 1;
    background-color: #1f1f1f;
    top: 50px;
}

.links-horizontal {
    display: none;
}

.link-expandable {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    --link-hover-color: #313131;
}

.link-expandable:focus-within{
    background-color: var(--link-hover-color);
}

.link-expandable:focus-within > .link-child-container {
    display: flex;
}

.link-child-container {
    display: none;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: absolute;
    z-index: 1;
    background-color: #1f1f1f;
    top: 50px;
}

.links-vertical .link-child-container {
    right: 100%;
    top: auto;
    bottom: auto;
}

.link-text {
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    white-space: nowrap;
}

.non-clickable {
    pointer-events: none;
}

.active {
    color: deepskyblue;
}

.inactive {
    color: white;
}

.link-child-indicator {
    padding: 13px 0 0 15px;
    margin-right: -10px;
    font-size: smaller;
}

/* For wide displays where we show the horizontal navigation bar */
@media (min-width: 700px) {
    .hamburger-button {
        display: none;
    }
    
    .links-horizontal {
        display: flex;
        flex-direction: row;
    }

    .link-expandable {
        justify-content: center;
    }

    .link-child-indicator {
        display: none;
    }
}

/* For devices using mice where we perform actions on hover */
@media (pointer: fine) {
    .link-expandable:hover{
        background-color: var(--link-hover-color);
    }
    
    .link-expandable:hover > .link-child-container {
        display: flex;
    }
    
    .hamburger-button img:hover {
        filter: drop-shadow(0px 0px 10px dodgerblue);
    }
    
    .parent-label {
        cursor: default;
    }
}