@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.ScooberSplat {
    padding-bottom: 50px;
    
    & p, & a, & a:link, & li {
        width: 75%;
        font-family: 'Rubik', sans-serif;
        font-optical-sizing: auto;
        font-weight: 350;
        font-style: normal;
        color: white;
    }
    
    & li {
        text-align: left;
        margin-left: 10%;
    }
}

.ScooberSplatLogo {
    max-width: 75%;
    max-height: 50vh;
    margin-top: 50px;
}

.ScooberSplatRun {
    max-height: 150px;
}

.Videos {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.Video {
    width: min(560px, 90vw);
    aspect-ratio: 16 / 9;
    border: 0;
}

.grid {
    column-count: 2;
    column-width: 600px;
    column-gap: 40px;
    
    margin-top: 25px;
    margin-bottom: 25px;
    
    margin-left: max(15px, calc(50% - 620px));
    margin-right: max(15px, calc(50% - 620px));
    
    * {
        display: inline-block;
    }
}