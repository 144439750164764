.Game {
    background-color: #000c2d;
    min-height: calc(100svh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.ItchEmbed {
    border: none;
    width: 100%;
    height: auto;
    flex-grow: 1;
    flex-shrink: 1;
}