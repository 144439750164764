@font-face {
  font-family: 'OCR-A';
  src: url("./assets/OCRA.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  color-scheme: dark;
}

text, p, a, li, span {
  font-family: 'OCR-A', sans-serif;
}

.Root {
  text-align: center;
}

.Root-header {
  background:
          linear-gradient(135deg, steelblue, transparent),
          url("./assets/noise.svg");
  min-height: calc(100svh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
